define('ember-pikaday/components/pikaday-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    attributeBindings: ['readonly', 'disabled', 'placeholder'],

    setupPikaday: _ember['default'].on('didInsertElement', function () {
      var that = this;

      var options = {
        field: this.$()[0],
        onOpen: _ember['default'].run.bind(this, this.onPikadayOpen),
        onClose: _ember['default'].run.bind(this, this.onPikadayClose),
        onSelect: _ember['default'].run.bind(this, this.onPikadaySelect),
        onDraw: _ember['default'].run.bind(this, this.onPikadayRedraw),
        firstDay: 1,
        format: this.get('format') || 'DD.MM.YYYY',
        yearRange: that.determineYearRange(),
        theme: this.get('theme') || null
      };

      if (this.get('i18n')) {
        options.i18n = this.get('i18n');
      }

      var pikaday = new Pikaday(options);

      this.set('pikaday', pikaday);
      this.setPikadayDate();

      this.addObserver('value', function () {
        that.setPikadayDate();
      });
    }),

    teardownPikaday: _ember['default'].on('willDestroyElement', function () {
      this.get('pikaday').destroy();
    }),

    setPikadayDate: function setPikadayDate() {
      this.get('pikaday').setDate(this.get('value'), true);
    },

    onPikadayOpen: _ember['default'].K,

    onPikadayClose: function onPikadayClose() {
      if (this.get('pikaday').getDate() === null) {
        this.set('value', null);
      }
    },

    onPikadaySelect: function onPikadaySelect() {
      this.userSelectedDate();
    },

    onPikadayRedraw: _ember['default'].K,

    userSelectedDate: function userSelectedDate() {
      var selectedDate = this.get('pikaday').getDate();

      if (this.get('useUTC')) {
        selectedDate = moment.utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
      }

      this.set('value', selectedDate);
    },

    determineYearRange: function determineYearRange() {
      var yearRange = this.get('yearRange');

      if (yearRange) {
        if (yearRange.indexOf(',') > -1) {
          var yearArray = yearRange.split(',');

          if (yearArray[1] === 'currentYear') {
            yearArray[1] = new Date().getFullYear();
          }

          return yearArray;
        } else {
          return yearRange;
        }
      } else {
        return 10;
      }
    },

    autoHideOnDisabled: _ember['default'].observer('disabled', 'pikaday', function () {
      if (this.get('disabled') && this.get('pikaday')) {
        this.get('pikaday').hide();
      }
    })
  });
});
/* globals Pikaday, moment */