define('ember-flash-messages/queue', ['exports', 'ember', 'ember-flash-messages/models/message', 'ember-flash-messages/utils/default-for'], function (exports, _ember, _message, _defaultFor) {
  'use strict';

  exports.__esModule = true;
  exports.default = _ember.default.ArrayProxy.extend(_ember.default.Evented, {

    /* Options */

    animationDuration: 500,
    interval: 3000,

    /* Properties */

    content: _ember.default.A(),
    currentMessage: _ember.default.computed.oneWay('timedMessages.firstObject'),
    untimedMessages: _ember.default.computed.filterBy('content', 'timed', false),
    timedMessages: _ember.default.computed.filterBy('content', 'timed', true),

    /* We declare the private properties on the queue so the
    class can be extended easily */

    _hider: _ember.default.Object.create(),
    _remover: _ember.default.Object.create(),

    /* Public methods */

    clear: function () {
      this.set('content', _ember.default.A());
    },

    pushMessage: function (messageProperties) {
      var message, newDuration, multiplier;

      /* Allow message to be passed as an object */

      ['content', 'type'].forEach(function (property) {
        var propertyExists = !!messageProperties[property];

        _ember.default.assert('You must pass the ' + property + ' property to flashMessage', propertyExists);
      });

      /* Covers cases with no duration and duration of zero */

      if (!messageProperties.duration) {
        messageProperties.duration = (0, _defaultFor.default)(messageProperties.duration, this.get('interval'));
      }

      message = _message.default.create(messageProperties);

      /* Add animation time to message duration */

      if (message.get('timed')) {
        multiplier = this.get('timedMessages.length') > 0 ? 1 : 2;
        newDuration = this.get('animationDuration') * multiplier;

        message.incrementProperty('duration', newDuration);
      }

      this.pushObject(message);
    },

    removeMessage: function (message) {

      /* If the message is in the timed queue and it's being
      removed by an early click, cancel the timers that would
      have eventually removed the message from the queue */

      if (this.indexOf(message) > -1) {
        this.removeObject(message);

        if (message.get('timed')) {
          _ember.default.run.cancel(this.get('_hider.' + message.get('createdAt')));

          _ember.default.run.cancel(this.get('_remover.' + message.get('createdAt')));
        }
      } else {
        _ember.default.warn('Message not found in message queue: ' + JSON.stringify(message));
      }
    },

    /* Private methods */

    _queueDidChange: function () {
      var currentMessage = this.get('currentMessage');
      var duration, earlyDuration;

      if (currentMessage) {
        duration = currentMessage.get('duration');
        earlyDuration = duration - this.get('animationDuration');

        /* Schedule the timed message to be visually hidden */

        this.set('_hider.' + currentMessage.get('createdAt'), _ember.default.run.later(this, function () {
          this.trigger('willHideQueue');
        }, earlyDuration));

        /* Schedule the timed message to be removed from the queue */

        this.set('_remover.' + currentMessage.get('createdAt'), _ember.default.run.later(this, function () {
          this.removeMessage(currentMessage);
        }, duration));
      }
    }.observes('currentMessage')

  }).create();
});