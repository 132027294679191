define('ember-flash-messages/initializers/ember-flash-messages', ['exports', 'ember', 'ember-flash-messages/queue'], function (exports, _ember, _queue) {
  'use strict';

  exports.__esModule = true;
  exports.default = {
    name: 'ember-flash-messages',

    initialize: function (container, app) {
      var flashMessage = function (message) {
        if (typeof message === 'string') {
          message = {
            type: arguments[0],
            content: arguments[1]
          };
        }

        _queue.default.pushMessage(message);
      };

      app.register('flashMessage:main', flashMessage, {
        instantiate: false
      });

      ['route', 'controller', 'view'].forEach(function (place) {
        app.inject(place, 'flashMessage', 'flashMessage:main');
      });
    }
  };
});