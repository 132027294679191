define('ember-in-viewport/mixins/in-viewport', ['exports', 'ember', 'ember-in-viewport/utils/can-use-dom', 'ember-in-viewport/utils/can-use-raf', 'ember-in-viewport/utils/is-in-viewport', 'ember-in-viewport/utils/check-scroll-direction'], function (exports, _ember, _emberInViewportUtilsCanUseDom, _emberInViewportUtilsCanUseRaf, _emberInViewportUtilsIsInViewport, _emberInViewportUtilsCheckScrollDirection) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  var setProperties = _ember['default'].setProperties;
  var deprecate = _ember['default'].deprecate;
  var computed = _ember['default'].computed;
  var merge = _ember['default'].merge;
  var typeOf = _ember['default'].typeOf;
  var assert = _ember['default'].assert;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var $ = _ember['default'].$;
  var scheduleOnce = run.scheduleOnce;
  var debounce = run.debounce;
  var bind = run.bind;
  var next = run.next;
  var not = computed.not;
  var forEach = _ember['default'].EnumerableUtils.forEach;
  var classify = _ember['default'].String.classify;

  var defaultListeners = [{ context: window, event: 'scroll.scrollable' }, { context: window, event: 'resize.resizable' }, { context: document, event: 'touchmove.scrollable' }];

  var rAFIDS = {};
  var lastDirection = {};
  var lastPosition = {};

  exports['default'] = _ember['default'].Mixin.create({
    viewportExited: not('viewportEntered').readOnly(),

    _setInitialState: on('init', function () {
      var options = merge({
        viewportUseRAF: (0, _emberInViewportUtilsCanUseRaf['default'])(),
        viewportEntered: false,
        viewportListeners: defaultListeners
      }, this._buildOptions());

      setProperties(this, options);
    }),

    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      if (this.container) {
        return merge(defaultOptions, this.container.lookup('config:in-viewport'));
      }
    },

    _setupElement: on('didInsertElement', function () {
      var _this = this;

      if (!_emberInViewportUtilsCanUseDom['default']) {
        return;
      }

      this._deprecateOldTriggers();
      this._setInitialViewport(window);
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener(window, get(this, 'viewportScrollSensitivity'));

      var listeners = get(this, 'viewportListeners');

      if (!get(this, 'viewportUseRAF')) {
        forEach(listeners, function (listener) {
          var context = listener.context;
          var event = listener.event;

          _this._bindListeners(context, event);
        });
      }
    }),

    _teardown: on('willDestroyElement', function () {
      this._unbindListeners();
    }),

    _addObserverIfNotSpying: function _addObserverIfNotSpying() {
      if (!get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },

    _setViewportEntered: function _setViewportEntered() {
      var context = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      assert('You must pass a valid context to _setViewportEntered', context);

      var element = get(this, 'element');

      if (!element) {
        return;
      }

      var elementId = get(this, 'elementId');
      var viewportUseRAF = get(this, 'viewportUseRAF');
      var viewportTolerance = get(this, 'viewportTolerance');
      var $contextEl = $(context);
      var height = $contextEl.height();
      var width = $contextEl.width();
      var boundingClientRect = element.getBoundingClientRect();

      this._triggerDidAccessViewport((0, _emberInViewportUtilsIsInViewport['default'])(boundingClientRect, height, width, viewportTolerance));

      if (boundingClientRect && viewportUseRAF) {
        rAFIDS[elementId] = window.requestAnimationFrame(bind(this, this._setViewportEntered, context));
      }
    },

    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var $contextEl = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var sensitivity = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];

      assert('You must pass a valid context element to _triggerDidScrollDirection', $contextEl);
      assert('sensitivity cannot be 0', sensitivity);

      var elementId = get(this, 'elementId');
      var viewportEntered = get(this, 'viewportEntered');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: $contextEl.scrollTop(),
        left: $contextEl.scrollLeft()
      };

      var scrollDirection = (0, _emberInViewportUtilsCheckScrollDirection['default'])(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && viewportEntered) {
        this.trigger('didScroll', scrollDirection);
        this.trigger('didScroll' + classify(scrollDirection), scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },

    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var viewportEntered = get(this, 'viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      set(this, 'viewportEntered', hasEnteredViewport);

      this.trigger(triggeredEventName);
    },

    _unbindIfEntered: function _unbindIfEntered() {
      if (!get(this, 'viewportSpy') && get(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        set(this, 'viewportEntered', true);
      }
    },

    _setInitialViewport: function _setInitialViewport() {
      var _this2 = this;

      var context = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      assert('You must pass a valid context to _setInitialViewport', context);

      return scheduleOnce('afterRender', this, function () {
        _this2._setViewportEntered(context);
      });
    },

    _debouncedEventHandler: function _debouncedEventHandler(methodName) {
      var _this3 = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      assert('You must pass a methodName to _debouncedEventHandler', methodName);
      var validMethodString = typeOf(methodName) === 'string';
      assert('methodName must be a string', validMethodString);

      debounce(this, function () {
        _this3[methodName].apply(_this3, args);
      }, get(this, 'viewportRefreshRate'));
    },

    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var _this4 = this;

      var context = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var sensitivity = arguments.length <= 1 || arguments[1] === undefined ? 1 : arguments[1];

      assert('You must pass a valid context to _bindScrollDirectionListener', context);
      assert('sensitivity cannot be 0', sensitivity);

      var $contextEl = $(context);

      $contextEl.on('scroll.directional#' + get(this, 'elementId'), function () {
        _this4._debouncedEventHandler('_triggerDidScrollDirection', $contextEl, sensitivity);
      });
    },

    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var context = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      assert('You must pass a valid context to _bindScrollDirectionListener', context);

      var elementId = get(this, 'elementId');

      $(context).off('scroll.directional#' + elementId);
      delete lastPosition[elementId];
      delete lastDirection[elementId];
    },

    _bindListeners: function _bindListeners() {
      var _this5 = this;

      var context = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var event = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      assert('You must pass a valid context to _bindListeners', context);
      assert('You must pass a valid event to _bindListeners', event);

      $(context).on(event + '.' + get(this, 'elementId'), function () {
        _this5._debouncedEventHandler('_setViewportEntered', context);
      });
    },

    _unbindListeners: function _unbindListeners() {
      var elementId = get(this, 'elementId');
      var listeners = get(this, 'viewportListeners');

      if (get(this, 'viewportUseRAF')) {
        next(this, function () {
          window.cancelAnimationFrame(rAFIDS[elementId]);
          delete rAFIDS[elementId];
        });
      }

      forEach(listeners, function (listener) {
        var context = listener.context;
        var event = listener.event;

        $(context).off(event + '.' + elementId);
      });

      this._unbindScrollDirectionListener(window);
    },

    _deprecateOldTriggers: function _deprecateOldTriggers() {
      var _this6 = this;

      var directions = ['Up', 'Down', 'Left', 'Right'];

      forEach(directions, function (direction) {
        var triggerName = 'didScroll' + direction;
        var isListening = _this6.has(triggerName);
        deprecate('[ember-in-viewport] ' + triggerName + ' is deprecated, please use `didScroll(direction)` instead.', !isListening);
      });
    }
  });
});