define('ember-flash-messages/components/message-queue', ['exports', 'ember', 'ember-flash-messages/utils/computed/insert', 'ember-flash-messages/queue'], function (exports, _ember, _insert, _queue) {
  'use strict';

  exports.__esModule = true;
  exports.default = _ember.default.Component.extend({

    /* Options */

    className: 'flash_queue',
    interval: _ember.default.computed.alias('queue.interval'),

    /* Properties */

    animationDuration: _ember.default.computed.alias('queue.animationDuration'),
    attributeBindings: ['dataTest:data-test'],
    classNameBindings: ['className'],
    currentMessage: _ember.default.computed.oneWay('queue.currentMessage'),
    dataTest: 'flash-queue',
    shouldShow: _ember.default.computed.or('currentMessage', 'untimedMessages.length'),
    untimedMessages: _ember.default.computed.oneWay('queue.untimedMessages'),

    queue: function () {
      return _queue.default;
    }.property().readOnly(),

    /* Methods */

    actions: {
      removeMessage: function (message) {
        this.get('queue').removeMessage(message);
      }
    },

    clear: function () {
      this.get('queue').clear();
    },

    getQueueLength: function () {
      return this.get('queue.timedMessages.length');
    }

  });
});