define('ember-flash-messages/models/message', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;
  exports.default = _ember.default.Object.extend({
    content: null,
    duration: null,
    type: null,

    /* Use like an ID to identify each message */

    createdAt: function () {
      return Date.now();
    }.property(),

    timed: function () {
      return this.get('duration') !== 0;
    }.property('duration')
  });
});