define("emberx-select/templates/components/x-select", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "emberx-select/templates/components/x-select.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["option", ["subexpr", "component", ["x-option"], ["select", ["get", "this", ["loc", [null, [3, 40], [3, 44]]]], "register", ["subexpr", "action", ["registerOption"], [], ["loc", [null, [3, 54], [3, 79]]]], "unregister", ["subexpr", "action", ["unregisterOption"], [], ["loc", [null, [3, 91], [3, 118]]]]], ["loc", [null, [3, 11], [3, 119]]]]], ["loc", [null, [2, 2], [4, 3]]]]], [], ["loc", [null, [1, 0], [5, 2]]]]],
      locals: [],
      templates: []
    };
  }());
});