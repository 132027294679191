define('ember-flash-messages/utils/computed/insert', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;

  exports.default = function (dependentKey, string) {
    return function () {
      var inCorrectFormat = string.indexOf('{{value}}') > -1;

      _ember.default.assert('You must pass a string in the format "Some stuff {{value}}" as the second argument of Utils.computed.insert', inCorrectFormat);
      return string.replace('{{value}}', this.get(dependentKey));
    }.property(dependentKey);
  };
});